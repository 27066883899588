export enum MapWidgetId {
  PREDICT_MAP_WIDGET = 'PREDICT_MAP_WIDGET',
  PLAN_MAP_WIDGET = 'PLAN_MAP_WIDGET',
  ECONOMICS_MAP_WIDGET = 'ECONOMICS_MAP_WIDGET',
}

export enum MapSourceId {
  VEGA_SOURCE = 'VEGA_SOURCE',
  PREDICTION_SOURCE = 'PREDICTION_SOURCE',
}
export enum ECombinedChartImpactCurveWidget {
  LATERAL_LENGTH = 'lateralLength',
  PROPPANT_PER_FT = 'proppantPerFt',
}
export enum ImpactCurveWidgetType {
  LATERAL_LENGTH = 'lateralLength',
  PROPPANT_PER_FT = 'proppantPerFt',
  PROPPANT_PER_FLUID_RATIO = 'proppantPerFluidRatio',
  NEAREST_SAME_RESERVOIR = 'nearestSameReservoirNeighborSpacing',
}

export enum EconomicsCurveWidgetType {
  GRAPH_CASHFLOW_CUM = 'graph_cashflow_cum',
  GRAPH_CASHFLOW_PROD = 'graph_cashflow_prod',
}
